var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"flex-v",style:({
      'padding-top': '6vh',
      'background-image': 'url(' + _vm.picUrl + ')',
      'background-repeat': 'no-repeat',
      'background-size': '100% 100%',
      '-moz-background-size': '100% 100%',
      'min-height': '800px',
    })},[_c('div',{staticStyle:{"color":"#fff","font-weight":"bold","font-size":"16px","margin-bottom":"20px"}},[_vm._v(" 核销奖品 ")]),_c('van-field',{staticStyle:{"margin-bottom":"20px"},attrs:{"label":"手机号","placeholder":"请输入手机号"},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}}),_c('van-button',{attrs:{"type":"primary","disabled":!_vm.tel},on:{"click":_vm.search}},[_vm._v("搜索")]),_c('div',{staticStyle:{"font-size":"12px","text-align":"center","margin":"8px 0px"}},[_vm._v(" *请交由工作人员核销奖品 ")]),_c('div',{staticStyle:{"color":"#fff","font-weight":"bold","font-size":"16px","margin-top":"10px"}},[_vm._v(" 中奖人信息 ")]),_vm._l((_vm.list),function(item,i){return _c('div',{key:i,staticStyle:{"padding":"4px 28px","color":"#515a6e","font-size":"14px","text-align":"left"}},[_c('van-row',[_c('van-col',{attrs:{"span":"18"}},[_c('div',[_vm._v("姓名："+_vm._s(item.name))]),_c('div',[_vm._v("手机："+_vm._s(item.mobile))]),_c('div',[_vm._v("地址："+_vm._s(item.address))]),_c('div',[_vm._v("奖品："+_vm._s(item.prize_name))]),_c('div',[_vm._v("状态："+_vm._s(_vm.statusFormate(item.status)))])]),_c('van-col',{attrs:{"span":"6"}},[_c('van-button',{staticStyle:{"margin-top":"30px"},attrs:{"round":"","type":"info","disabled":item.status == 'AWARD'},on:{"click":function($event){return _vm.audit(item.id)}}},[_vm._v("核销")])],1)],1)],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }